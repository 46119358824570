<template>
    <section class="login_wrap bg-white">
        <div class="row no-gutters min-vh-100">
            <div class="col-lg-5 d-none d-lg-block bg-primary login_slider_bg" style="background-image: url('../../assets/images/login/login_bg.png')">
                <a class="p-3 d-inline-block login_logo" href="">
              <img class="w-80" src="../../assets/images/logo.svg" alt="" />
            </a>
                <div class="login_slider_box">
                    <div class="text-center pb-2">
                        <div class="login_slider_box_img">
                            <img class="d-inline-block mw-100" src="../../assets/images/login/login_img.png" alt="" />
                        </div>
                    </div>
                    <div class="text-white text-center">
                        <h1 class="text-white">Protect Your Nest Egg and Enjoy Your Retirement</h1>
                        <p>
                            We're here to help. The Heyday team has worked with hundreds of retirees to help minimize financial risk and maximize their income.
                        </p>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-7 d-block d-sm-flex align-items-center justify-content-center">
                <div class="login_box">
                    <div class="login_box_form">
                        <a class="login_tab_logo d-inline-block d-lg-none mb-4" href="">
                           <img src="/images/HeyDay-Logo.svg" alt="Logo" />
                        </a>
                        <h2 class="text-dark mb-1">Reset Password?</h2>
                        <span class="font-size-sm mb-4 d-block">Please enter the email associated with your account.</span>
                        <transition name="fade">
                            <p class="alert alert-danger" v-if="error">{{ error }}</p>
                            <p class="alert alert-success" v-if="message">{{ message }}</p>
                        </transition>
                        <form action="" @submit.prevent="sendEmail">
                            <div class="form-group">
                                <label for="email_address" class="text-dark mb-1">Email</label>
                                <input type="text" name="email" id="email_address" v-model="email" placeholder="info@heydayretirement.com " class="form-control">
                            </div>
                            <button class="btn btn-primary btn-lg btn-block mt-4 mb-2" :disabled="!email">
                               <transition name="fade" mode="out-in">
                                    <span v-if="!emailSending">Send <i class="fas fa-arrow-right pl-1"></i></span>
                                    <span v-else>Sending... <i class="fas fa-spinner fa-pulse"></i></span>
                                </transition>
                            </button>
                        </form>
                        <div class="text-secondary font-size-lg mt-5 text-center">
                            Already have an account?
                            <router-link to="/login">Log in</router-link>
                        </div>
                    </div>
                    <div class="login_box_copyright font-size-sm text-secondary">
                        Copyright {{ new Date().getFullYear() }} <a href="https://www.heydayretirement.com/" target="_blank" class="text-secondary">Heydayretirement.com</a> All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { firebase } from "@/utils/firebase.js";

export default {
    name: "ForgotPassword",
    components: {},
    data() {
        return {
            email: "",
            error: null,
            emailSending: false,
            message: null
        };
    },
    mounted() {
        console.log(process.env);
    },
    methods: {
        resetForm() {
            this.email = "";
            this.error = null;
            this.emailSending = false;
            this.message = null;
        },
        sendEmail() {
            if (!this.email) {
                this.error = "Please type in a valid email address.";
                return;
            }
            this.error = null;
            this.emailSending = true;
            firebase
                .auth()
                .sendPasswordResetEmail(this.email)
                .then(() => {
                    this.emailSending = false;
                    this.message = 'Check your email inbox to find reset password link.';
                    setTimeout(() => {
                        this.resetForm();
                    }, 3000);
                })
                .catch(error => {
                    this.emailSending = false;
                    this.error = error.message;
                    setTimeout(() => {
                        this.resetForm();
                    }, 3000);
                });
        },
    },
};
</script>
